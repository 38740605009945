import React from 'react'
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import Login from './component/Login/Login';
import Dashboard from './component/Dashboard/Dashboard';
import RecordPickup from './component/Request/RecordPickup/RecordPickup';
import RecordRetrival from './component/Request/RecordRetrival/RecordRetrival';
// import ScanningRequest from './component/Request/ScanningRequest/ScanningRequest';
// import Shredding from './component/Request/Shredding/Shredding';
// import OtherRequest from './component/Request/Other/OtherRequest';
// import ScanningReports from './component/Response/ScanningReports/ScanningReports';
import InwardReports from './component/Response/InwardReports/InwardReports';
import RetrivalReports from './component/Response/RetrivalReport/RetrivalReport';
import StockReports from './component/Response/StockReports/StockReports';
import Profile from './component/Profile/Profile';
import PrivatRoute from './component/HOC/PrivateRoute';
import Changepassword from './component/changepassword/Changepassword';
import RecorRetrival from './component/Response/RecordRetrival/RecordRetrival'
import PickupReports from './component/Response/PickupReports/PickupReport';
import OtherReport from './component/Response/OtherRequestReport/OtherRequest';
import ShreddingRequest from './component/Response/ShreddingReports/ShreddingRequest';
import ScanningRequestReport from './component/Response/ScanningRequestReport/ScanningRequest';
import BoxReport from './component/Response/BoxReport/Boxreport'
import PageNotFound from './component/PageNotFound/PageNotFound';
import FileReport from './component/Response/FileReport/FileReport';

function App() {
  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/Profile" element={<PrivatRoute><Profile /></PrivatRoute>} />
          <Route path="/Dashboard" element={<PrivatRoute><Dashboard /></PrivatRoute>} />
          <Route path="/RecordPickup" element={<PrivatRoute><RecordPickup /></PrivatRoute>} />
          <Route path="/RecordRetrival" element={<PrivatRoute><RecordRetrival /></PrivatRoute>} />
          {/* <Route path="/ScanningRequest" element={<PrivatRoute><ScanningRequest /></PrivatRoute>} /> */}
          {/* <Route path="/Shredding" element={<PrivatRoute><Shredding /></PrivatRoute>} /> */}
          {/* <Route path="/OtherRequest" element={<PrivatRoute><OtherRequest /></PrivatRoute>} /> */}
          {/* <Route path="/ScanningReports" element={<PrivatRoute><ScanningReports /></PrivatRoute>} /> */}
          <Route path="/InwardReports" element={<PrivatRoute><InwardReports /></PrivatRoute>} />
          <Route path="/RetrivalReports" element={<PrivatRoute><RetrivalReports /></PrivatRoute>} />
          <Route path="/StockReports" element={<PrivatRoute><StockReports /></PrivatRoute>} />
          <Route path="/BoxReport" element={<PrivatRoute><BoxReport /></PrivatRoute>} />
          <Route path="/FileReport" element={<PrivatRoute><FileReport/></PrivatRoute>} />
          <Route path="/Changepassword" element={<PrivatRoute><Changepassword /></PrivatRoute>} />

          {/* <Route path="/RecorRetrivalReport" element={<PrivatRoute><RecorRetrival /></PrivatRoute>} />
          <Route path="/RecordPickupReport" element={<PrivatRoute><PickupReports /></PrivatRoute>} />
          <Route path="/OtherRequestReport" element={<PrivatRoute><OtherReport /></PrivatRoute>} />
          <Route path="/ShreddingRequestReport" element={<PrivatRoute><ShreddingRequest /></PrivatRoute>} />
          <Route path="/ScanningRequestReport" element={<PrivatRoute><ScanningRequestReport /></PrivatRoute>} /> */}

          <Route path="*" element={<PageNotFound />} />
        </Routes>
      </Router>
    </>
  );
}

export default App;