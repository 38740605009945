import React, { useState, useEffect } from 'react'
import Navbar from '../../Navbar/Navbar'
import { rmsRequest, Mail, Totallocation, lastcount, UpdateCount, AllDepartment } from '../../../api/index'
import './RecordPickup.css'
import { BsFillChatSquareQuoteFill } from 'react-icons/bs';
import svg from '../../Images/phoneicon.png'
import Footer from '../../Navbar/Footer.js'
import { sweetAlertModel } from '../../SweetAlert/SweetAlert';

function RecordPickup() {
    const [todayDateVal, setTodayDateVal] = useState('')
    const [mandatoryfield, setMandatoryfield] = useState({
        noOfFiles: false,
        deparment: false
    });
    const [totallocation, setTotallocation] = useState([]);
    const [RequestId, setRequestId] = useState()
    const [count, setCount] = useState()
    const [contactNumber, setContactNumber] = useState('')
    const [allDepartment, setAllDepartment] = useState([])
    const whid = localStorage.getItem('Warehouse_ID')

    const errorFiledStyle = {
        border: '0.3px solid red',
        boxShadow: '0.14px 0.13px 5px red'
    }

    useEffect(() => {
        const fetchdata = async () => {
            const Totallocationresult = await Totallocation();
            setTotallocation(Totallocationresult)
            const lastcountval = await lastcount(whid)
            setCount(lastcountval[0]["hh"])
            const cust_id = localStorage.getItem('User_Name').split(" ").join("").slice(0, 4)
            const requestid = whid + '-' + cust_id.toUpperCase() + '-' + 'PCK' + '-' + lastcountval[0]["hh"].padStart(6, '0')
            setRequestId(requestid)
            TodayDate()

            if (localStorage.getItem('Dptname') === 'ADMIN') {
                const getallDepartment = await AllDepartment();
                setAllDepartment(getallDepartment)
            }
        }
        fetchdata()

    }, [])

    const TodayDate = () => {
        const date = new Date();
        let currentDay = String(date.getDate()).padStart(2, '0');
        let currentMonth = String(date.getMonth() + 1).padStart(2, "0");
        let currentYear = date.getFullYear();
        let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;
        // document.getElementById('Pickupdate').value = currentDate
        setTodayDateVal(currentDate)
    }

    const handleChangeNumber = (e) => {
        if (e.target.value.length > 10) return false
        setContactNumber(e.target.value)
    }

    const handleChangeDisableError = (fieldsType) => {
        setMandatoryfield({ ...mandatoryfield, [fieldsType]: false })
    }

    const handleClick = async (e) => {
        e.preventDefault();
        // const location = document.getElementById('Location').value;
        const location = localStorage.getItem('Wh_name');
        const noof_files = document.getElementById('nooffiles').value;
        const request_date = document.getElementById('Pickupdate').value;
        // const request_time = document.getElementById('pickuptime').value;
        const request_time = '';
        const remark = document.getElementById('remark').value;
        const locationid = localStorage.getItem('Warehouse_ID')
        const fileid = locationid + Math.floor(Math.random() * 10000000)
        // const requestid = locationid + '-' + Math.floor(Math.random() * 10000000) + '-' + 'RP'
        // const Contactperson = document.getElementById('ContactPerson').value;
        const Contactperson = document.getElementById('contactPerson').value;
        const Personno = document.getElementById('Contactpersonnumber').value;
        const Deparment = document.getElementById('department').value;

        const message = {
            Location: location,
            NoofFiles: noof_files,
            date: request_date,
            Remark: remark,
            clientName: localStorage.getItem('User_Name'),
            contactPerson: Contactperson,
            contactPersonNo: Personno,
            department: Deparment,
            RequestId: RequestId,
            currentDate: todayDateVal,
            requestType: 'RecordPickup'
        }

        if (!noof_files) {
            setMandatoryfield({ ...mandatoryfield, noOfFiles: true })
        }
        else if (!Deparment) {
            setMandatoryfield({ ...mandatoryfield, deparment: true })
        }
        else {
            // console.log('RecordPickup', location, noof_files, request_date, request_time, '', '', '', '', '', '', remark, localStorage.getItem('User_ID'), fileid, locationid, RequestId, localStorage.getItem('CUST_ID'), '', Contactperson, Personno, Deparment)
            const result = await rmsRequest('RecordPickup', location, noof_files, request_date, request_time, '', '', '', '', '', '', remark, localStorage.getItem('User_ID'), fileid, locationid, RequestId, localStorage.getItem('CUST_ID'), '', Contactperson, Personno, Deparment)
            // const result = 'Inserted';
            if (result === 'Inserted') {
                await Mail(message);
                await UpdateCount(whid, count)
                sweetAlertModel('Success', `Requestid ${RequestId} generated`, 'success', '/Dashboard')
            }
            else {
                sweetAlertModel('Error', `Server Error`, 'error', '/RecordPickup')
            }
        }
    }

    return (
        <>
            <div className="PickupReq">
                <Navbar />
                <div className='rec_pickup'>
                    <div className='svg_div' >
                        <img src={svg} alt="Dox And Box Bg" />
                    </div>
                    <form autoComplete='off' className='bg-white' style={{ margin: "0px 20px 20px 15px", boxShadow: "8px 8px 5px 1px grey", padding: "30px 15px 0px", minHeight: "60vh", borderRadius: "7px" }}>
                        <h3 className='pb-3'>Request for record collection <BsFillChatSquareQuoteFill style={{ margin: "0 0 -9px 0", fontSize: "30px" }} /></h3>
                        <div className='form-row'>
                            <div className="form-group col-md-6 ">
                                <label htmlFor='nooffiles'>No of Files <span className='text-danger'>*</span> </label>
                                <input type="number" placeholder="Total no of Files" className="form-control" id="nooffiles" style={mandatoryfield.noOfFiles ? errorFiledStyle : {}} onBlur={() => { handleChangeDisableError('noOfFiles') }} />
                                {mandatoryfield.noOfFiles && <p className='text-danger mb-0 mt-1' > Please provide No. of Files</p>}
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor='Pickupdate'>Pickup Date </label>
                                <input type="date" className="form-control" id='Pickupdate' defaultValue={todayDateVal} />
                            </div>
                        </div>

                        <div className="form-row">
                            <div className="form-group col-md-6">
                                <label htmlFor='contactPerson'>Contact Person</label>
                                <input type="text" placeholder="Contact Person" className="form-control" id="contactPerson" />
                            </div>

                            <div className="form-group col-md-6">
                                <label htmlFor='Contactpersonnumber'>Contact Number</label>
                                <input type="number" className="form-control" id='Contactpersonnumber' value={contactNumber} onChange={handleChangeNumber} placeholder='XXXXX XXXXX' required />
                            </div>
                        </div>
                        <div className="form-group">
                            <label htmlFor='department'>Department <span className='text-danger'>*</span></label>
                            {
                                localStorage.getItem('Dptname') === 'ADMIN'
                                    ?
                                    <select className="form-control" id='department' style={mandatoryfield.deparment ? { ...errorFiledStyle, height: '35px' } : { height: '35px' }} onChange={() => { handleChangeDisableError('deparment') }}>
                                        <option hidden value=''>Select Department</option>
                                        {
                                            allDepartment.map(((department, index) => (
                                                <option key={index} value={department.DEPTNAME}>{department.DEPTNAME}</option>
                                            )))

                                        }
                                    </select> :
                                    <input className="form-control" type="text" id='department' defaultValue={localStorage.getItem('Dptname')} disabled />
                            }

                            {mandatoryfield.deparment && <p className='text-danger mb-0 mt-1' > Please select Department</p>}

                        </div>
                        <div className="form-group">
                            <label htmlFor='remark'>Remarks</label>
                            <textarea className="form-control" rows={3} placeholder="Comments..." type="text" id='remark' style={{ resize: 'none' }} />
                        </div>

                        <div className="form-group" >
                            <button type="submit" className="maroon_btn btn float-right mb-4" onClick={handleClick}>Submit</button>
                            <button type="reset" className="dark_btn btn mr-4 float-right mb-4">Reset</button>
                        </div>
                    </form>
                </div>
            </div>
            <Footer />
        </>
    )
}

export default RecordPickup
