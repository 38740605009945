import React from 'react';

export default function Footer() {

    const footerStyle = {
        background: "#222222ef",
        padding: "10px 0"
    }
    return (
        <>
            <footer className='text-center text-light' style={footerStyle}>
                Copyright &copy; 2022-2023 Dox&Box.All right.reserved
            </footer>
        </>
    )
} 