import React from 'react'
import './PageNotFound.css'
import { useNavigate } from 'react-router-dom'

const PageNotFound = () => {
    const navigate = useNavigate();
    return (
        <>
            <div className='position-relative pageNotFound-container' >
                <div className='position-absolute pageNotFound-subDiv text-center' >
                    <h1 className='pageNotFound-title m-0'>404</h1>
                    <p>Oops!!</p>
                    <p>That page does't Exist or is Unavailable.</p>
                    <button className='py-3 px-4 btn btn-primary' onClick={() => { navigate(-1) }}>Go Back</button>
                </div>
            </div>
        </>
    )
}

export default PageNotFound;