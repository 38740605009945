import React, { useState, useEffect } from 'react';
import './Sidebar.css'
import { Totallocation } from '../../api/index'
import { MdDashboard, MdArrowDropDown, MdEditNote } from 'react-icons/md';
import { BsFillChatSquareQuoteFill } from 'react-icons/bs';
import { ImLocation } from 'react-icons/im';
import { RiUserFill } from 'react-icons/ri';
import { CgLogOut } from 'react-icons/cg';
import { Link, useNavigate } from 'react-router-dom'


const SideBar = ({ openClass }) => {
  const [toggleList, setTogglList] = useState({
    requestList: false,
    reportList: false,
    accountLiat: false,
    locationList: false
  })

  const [totallocation, setTotallocation] = useState([])

  const navigate = useNavigate()

  useEffect(() => {
    const fetch = async () => {
      const result = await Totallocation(localStorage.getItem('CUST_ID'))
      setTotallocation(result)
    }
    localStorage.getItem('Dptname') === 'ADMIN' && fetch()
  }, [])


  const handleClick = () => {
    localStorage.clear();
    navigate('/')
  }
  const handleToggleList = (listType) => {
    setTogglList({ [listType]: !toggleList[listType] })
  }

  const handleChange = (e) => {
    const string = e
    const split = string.split(",")
    localStorage.setItem('Warehouse_ID', split[0])
    localStorage.setItem('Wh_name', split[1])
    window.location.reload()
  }

  return (
    <nav className={openClass === true ? 'closeslidernav slidernav' : ' slidernav'}>

      <ul className="navlist">
        <li>
          <Link className="menu-item" to="/Dashboard" style={{ padding: "10px 130px 10px 0" }}>
            <MdDashboard style={{ fontSize: "20px", margin: "-2px 5px" }} />Dashboard
          </Link>
        </li>

        <li onClick={() => handleToggleList('requestList')}>
          <span className="menu-item" style={{ padding: "10px 131px 10px 0" }}>
            <BsFillChatSquareQuoteFill style={{ fontSize: "17px", margin: "-2px 6px" }} />Request<MdArrowDropDown style={{ fontSize: "22px", marginBottom: "-6px" }} />
          </span>
        </li>
        {toggleList.requestList &&
          <ul className="innerul" id='reportinnerdiv'  >
            <Link to='/RecordPickup'><li style={{ borderTop: "1px solid #2f2f2f" }}>Record Collection</li></Link>
            <Link to='/RecordRetrival'><li>Record Retrieval</li></Link>
          </ul>
        }
        <li onClick={() => handleToggleList('reportList')}>
          <span className="menu-item" style={{ padding: "10px 124px 10px 0" }}>
            <MdEditNote style={{ fontSize: "28px", margin: "-3px 0 -5px" }} /> Reports <MdArrowDropDown style={{ fontSize: "22px", marginBottom: "-6px" }} />
          </span>
        </li>
        {toggleList.reportList &&
          <ul className="innerul" id='reportinnerdiv' >
            <Link to="/InwardReports"> <li style={{ borderTop: "1px solid #2f2f2f" }}>Inward Report</li></Link>
            <Link to="/RetrivalReports"> <li>Retrieval Report</li></Link>
            <Link to="/StockReports"> <li>Stock Report</li></Link>
          </ul>
        }
        <li onClick={() => handleToggleList('accountLiat')}>
          <span className="menu-item" style={{ padding: "10px 124px 10px 0" }}>
            <RiUserFill style={{ fontSize: "21px", margin: "-2px 5px 0px 3px" }} /> Account<MdArrowDropDown style={{ fontSize: "22px", marginBottom: "-6px" }} />
          </span>
        </li>
        {toggleList.accountLiat &&
          <ul className="innerprofileul" id='reportinnerdiv'>
            <Link to="/Profile"> <li style={{ borderTop: "1px solid #2f2f2f" }}>Profile</li></Link>
            <Link to="/Changepassword"> <li>Change Password</li></Link>
          </ul>
        }
        <li className='location-list' onClick={() => handleToggleList('locationList')}>
          <span className="menu-item" style={{ padding: "10px 0px 10px 0" }}>
            <ImLocation style={{ fontSize: "20px", margin: "-2px 0px" }} /> {localStorage.getItem('Wh_name')}<MdArrowDropDown style={{ fontSize: "22px", marginBottom: "-6px" }} />
          </span>
        </li>
        {toggleList.locationList &&
          <ul className="innerlocationul" id='reportinnerdiv'>
            {
              totallocation.map((ele) => (
                <li key={ele.WHid} onClick={() => { handleChange(`${ele.WHid},${ele.WHname}`) }} style={{ fontSize: "17px" }}>{ele.WHname.trim()} </li>
              ))
            }
          </ul>
        }
      </ul>

      <div className='sidebar-cust-details'>
        <table>
          <tbody>
            <tr>
              <td className='sidebar-cust-title' >Department:- </td>
              <td>&nbsp;{localStorage.getItem("Dptname") !== "null" ? localStorage.getItem("Dptname") : ""}</td>
            </tr>
            <tr className=' border-top'>
              <td className='sidebar-cust-title'>Customer:- </td>
              <td>&nbsp;{localStorage.getItem("User_Name")}</td>
            </tr>
          </tbody>
        </table>
      </div>

      <div className='logout-sidebar text-white py-2 px-2' onClick={handleClick}>
        <CgLogOut className='logout-icons mx-4' />Logout
      </div>
    </nav>
  );
};

export default SideBar;