import Swal from 'sweetalert2'

export const sweetAlertModel = (title, text, alertType, urlNavigate) => {
    Swal.fire({
        title: title,
        text: text,
        icon: alertType,
        allowOutsideClick: false,
        confirmButtonColor: '#3085d6',
        confirmButtonText: 'ok'
    }).then((result) => {
        if (result.isConfirmed) {
            window.location.href = urlNavigate
        }
    })
}