import axios from 'axios';
// const BASE_URL = `http://localhost:8008/api`
// const BASE_URL= `http://192.168.146.121:8010/api`
const BASE_URL= `https://snmapi.doxandbox.com/api`

export const UserLogin = async (uid_id, uid_pass) => {
    return axios.post(`${BASE_URL}/UserLogin`, { uid_id, uid_pass }).then(response => response.data).catch(error => console.log(error));
}

export const AuthTokenVerify = async (token) => {
    return axios.post(`${BASE_URL}/AuthTokenVerify`, { token }).then(response => response.data).catch(error => console.log(error))
}

export const PasswordChange = async (uid_id, uid_pass, whid, new_password) => {
    return axios.post(`${BASE_URL}/userpasswordchange`, { uid_id, uid_pass, whid, new_password }).then(response => response.data).catch(error => console.log(error));
}

export const ProfileDetails = async (uid_id) => {
    return axios.post(`${BASE_URL}/profiledetails`, { uid_id }).then(response => response.data).catch(error => console.log(error));
}

export const rmsReports = async (reportType, custId, Whid, startDate, endDate, department) => {
    return axios.post(`${BASE_URL}/reports`, { reportType, custId, Whid, startDate, endDate, department }).then(response => response.data).catch(error => console.log(error));
}

export const rmsRequest = async (request_type, location, noof_files, request_date, request_time, file_name, retrival_type, delivery_type, noof_pages, onsite, activity, remark, entry_by, FILEID, WHID, requestid, custid, TYPE, Contactperson, Personno, Deparment, Boxno, DESCN, Bookingid, city) => {
    return axios.post(`${BASE_URL}/request`, { request_type, location, noof_files, request_date, request_time, file_name, retrival_type, delivery_type, noof_pages, onsite, activity, remark, entry_by, FILEID, WHID, requestid, custid, TYPE, Contactperson, Personno, Deparment, Boxno, DESCN, Bookingid, city }).then(response => response.data).catch(error => console.log(error));
}

export const ReportData = async (uid_id, location_id, department) => {
    return axios.post(`${BASE_URL}/reportdata`, { uid_id, location_id, department }).then(response => response.data).catch(error => console.log(error));
}

export const Mail = async (requestData) => {
    return axios.post(`${BASE_URL}/sendEmail`, { requestData }).then(response => response.data).catch(error => console.log(error));
}

export const Totallocation = async () => {
    return axios.get(`${BASE_URL}/totallocation`).then(response => response.data).catch(error => console.log(error));
}

export const Dashboardetails = async (CUSTID, wh) => {
    return axios.post(`${BASE_URL}/dashboardetails`, { CUSTID, wh }).then(response => response.data).catch(error => console.log(error));
}

export const ReportdataBoxes = async (uid_id, location_id, department) => {
    return axios.post(`${BASE_URL}/reportdataboxes`, { uid_id, location_id, department }).then(response => response.data).catch(error => console.log(error));
}

export const Dashboardrequest = async () => {
    return axios.get(`${BASE_URL}/bargraph`).then(response => response.data).catch(error => console.log(error));
}

export const DashbaordetailsPie = async (CUSTID, wh) => {
    return axios.post(`${BASE_URL}/piegraph`, { CUSTID, wh }).then(response => response.data).catch(error => console.log(error));
}

export const RequestReport = async (cust_id, request_type) => {
    return axios.post(`${BASE_URL}/requestreport`, { cust_id, request_type }).then(response => response.data).catch(error => console.log(error));
}

export const BoxReports = async (Boxno, CUSTID, WH) => {
    return axios.post(`${BASE_URL}/boxreport`, { Boxno, CUSTID, WH }).then(response => response.data).catch(error => console.log(error));
}
export const FileReports = async (Boxno, CUSTID, WH, pickupNo, fileNo) => {
    return axios.post(`${BASE_URL}/filereport`, { Boxno, CUSTID, WH, pickupNo, fileNo }).then(response => response.data).catch(error => console.log(error));
}


export const IdCount = async (whid) => {
    return axios.post(`${BASE_URL}/idcount`, { whid }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateIdCount = async (whid, Idcount) => {
    return axios.post(`${BASE_URL}/updateidcount`, { whid, Idcount }).then(response => response.data).catch(error => console.log(error));
}

export const dashbaorScannedPages = async (custid, whid) => {
    return axios.post(`${BASE_URL}/dashbaorscannedpages`, { custid, whid }).then(response => response.data).catch(error => console.log(error));
}

// export const DepartmentData = async (uid_id, location_id) => {
//     return axios.post(`${BASE_URL}/departmentdata`, { uid_id, location_id }).then(response => response.data).catch(error => console.log(error));
// }

export const lastcount = async (whid) => {
    return axios.post(`${BASE_URL}/lastcount`, { whid }).then(response => response.data).catch(error => console.log(error));
}

export const UpdateCount = async (whid, Idcount) => {
    return axios.post(`${BASE_URL}/updatecount`, { whid, Idcount }).then(response => response.data).catch(error => console.log(error));
}

export const AllDepartment = async () => {
    return axios.get(`${BASE_URL}/AllDepartment`).then(response => response.data).catch(error => console.log(error));
}
export const InsertSubRecordReterival = async (subReterivalData) => {
    return axios.post(`${BASE_URL}/InsertSubRecordReterival`, { subReterivalData }).then(response => response.data).catch(error => console.log(error));
}