import React, { useState, useEffect } from "react";
import Navbar from "../../Navbar/Navbar";
import "./RecordRetrival.css";
import { rmsRequest, ReportData, ReportdataBoxes, IdCount, UpdateIdCount, AllDepartment, Mail, InsertSubRecordReterival } from "../../../api/index";
import Select from "react-select";
import svg from "../../Images/phoneicon.png";
import { BsFillChatSquareQuoteFill } from "react-icons/bs";
import Footer from "../../Navbar/Footer";
import { sweetAlertModel } from "../../SweetAlert/SweetAlert";

function RecordRetrival() {
  const [mandatoryfield, setMandatoryfield] = useState({
    type: false,
    deparment: false,
    fileBoxIndex: null,
    fileBoxIndexStatus: false
  });
  const retrivalTableObj = { fileBox: '', typesOfRetrival: '', typesOfDelivery: '' }
  const [retrivalTable, setRetrivalTable] = useState([retrivalTableObj])
  const [fileBoxData, setFileBoxData] = useState([])
  const [fileBoxType, setFileBoxType] = useState('')

  const [BookingId, setBooKingId] = useState();
  const [count, setCount] = useState();
  const [department, setDepartment] = useState([]);

  const errorFiledStyle = {
    height: '32px',
    border: '0.3px solid red',
    boxShadow: '0.14px 0.13px 5px red'
  }

  useEffect(() => {
    const fetchdata = async () => {
      const whid = localStorage.getItem("Warehouse_ID");
      const id = await IdCount(whid);
      const lastno = Number(id[0].RMSBookid) + 1;
      setCount(lastno);
      let BookingId = "BOOK" + "-" + whid + "-" + String(lastno).padStart(6, "0");
      setBooKingId(BookingId);

      let reportData;
      if (localStorage.getItem("Dptname") === "ADMIN") {
        const departmentList = await AllDepartment();
        setDepartment(departmentList);
        reportData = await ReportData(localStorage.getItem("CUST_ID"), localStorage.getItem("Warehouse_ID"), "All");
      }
      else {
        reportData = await ReportData(localStorage.getItem("CUST_ID"), localStorage.getItem("Warehouse_ID"),);
      }
      let options = reportData.map((ele) => {
        return { value: ele.Fileno, label: `${ele.Fileno}, ${ele.FileName}` };
      });
      setFileBoxData(options)
    };
    fetchdata();
  }, []);


  const handleAdd = (e) => {
    e.preventDefault();
    setRetrivalTable([...retrivalTable, retrivalTableObj]);
  };

  const handleRemove = (e) => {
    e.preventDefault();
    if (retrivalTable.length !== 1) {
      const tableData = [...retrivalTable]
      tableData.pop();
      setRetrivalTable(tableData);
    }
  };


  const handleChangeType = async (e) => {
    document.getElementById("type").disabled = "true";
    handleDisableErrorMesage('type')

    if (e.target.value === "File") {
      let reportData;
      setFileBoxType('File')
      if (localStorage.getItem("Dptname")) {
        reportData = await ReportData(localStorage.getItem("CUST_ID"), localStorage.getItem("Warehouse_ID"), localStorage.getItem("Dptname"));
      } else {
        reportData = await ReportData(localStorage.getItem("CUST_ID"), localStorage.getItem("Warehouse_ID"), "All");
      }

      let options = reportData.map((ele) => {
        return { value: ele.Fileno, label: `${ele.Fileno}, ${ele.FileName}` };
      });
      setFileBoxData(options)
    }
    else {
      let reportData;
      setFileBoxType('Box')
      if (localStorage.getItem("Dptname")) {
        reportData = await ReportdataBoxes(localStorage.getItem("CUST_ID"), localStorage.getItem("Warehouse_ID"), localStorage.getItem("Dptname"));

      } else {
        reportData = await ReportdataBoxes(localStorage.getItem("CUST_ID"), localStorage.getItem("Warehouse_ID"), "All");
      }

      let optionBox = reportData.map((ele) => {
        if (ele.Description) {
          return { value: `${ele.Boxno}^${ele.Description}`, label: `${ele.Boxno} , ${ele.Description}` };
        } else {
          return { value: `${ele.Boxno}^${ele.Description}`, label: `${ele.Boxno}` };
        }
      });
      setFileBoxData(optionBox)
    }
  };


  const handleChangeTableValue = (e, index, fileType) => {
    setMandatoryfield({ ...mandatoryfield, fileBoxIndex: null, fileBoxIndexStatus: false });

    let temp_retrivalTableObj = [...retrivalTable]
    if (fileType === 'File') {
      temp_retrivalTableObj[index].fileBox = e.value
    }
    else {
      let [box, description] = e.value.split("^");
      temp_retrivalTableObj[index].fileBox = box
      temp_retrivalTableObj[index].desc = description
    }
    setRetrivalTable(temp_retrivalTableObj);
  }

  const handleSaveToArr = (index, e) => {
    let temp_retrivalTableObj = [...retrivalTable]
    temp_retrivalTableObj[index][e.target.name] = e.target.value
    setRetrivalTable(temp_retrivalTableObj);
  }

  const handleDisableErrorMesage = (fieldsType) => {
    setMandatoryfield({ ...mandatoryfield, [fieldsType]: false })
  }

  const handleClick = async (e) => {
    e.preventDefault();
    const locationid = localStorage.getItem("Warehouse_ID");
    // const requestid = locationid + "-" + Math.floor(Math.random() * 10000000) + "-" + "RR";
    const requestid = null;
    const TYPE = document.getElementById("type").value;

    const fileid = locationid + Math.floor(Math.random() * 10000000);
    const Department = document.getElementById("department").value;
    const remarks = document.getElementById('remarks').value
    const cust_name = localStorage.getItem("User_Name")
    const data = [];


    const todayDate = new Date();
    let currentDay = String(todayDate.getDate()).padStart(2, '0');
    let currentMonth = String(todayDate.getMonth() + 1).padStart(2, "0");
    let currentYear = todayDate.getFullYear();
    let currentDate = `${currentYear}-${currentMonth}-${currentDay}`;

    let today = new Date();
    let date = today.toLocaleDateString("en-US");


    if (!TYPE) { setMandatoryfield({ ...mandatoryfield, type: true }) }
    else if (!Department) { setMandatoryfield({ ...mandatoryfield, deparment: true }); }
    else {

      retrivalTable.map((rowData, index) => {
        if (rowData.fileBox === '') {
          setMandatoryfield({ ...mandatoryfield, fileBoxIndex: index + 1, fileBoxIndexStatus: true });
          return 0
        }
      })

      let response_result = '';

      retrivalTable.map(async (rowData, index) => {
        data.push({
          Location: localStorage.getItem("Wh_name"),
          locationId: localStorage.getItem("Warehouse_ID"),
          Date: date,
          filenumber: rowData.fileBox,
          Department: Department,
          remark: remarks,
          typeofretrival: rowData.typesOfRetrival,
          typeofdelivery: rowData.typesOfDelivery,
          RequestType: "RecordRetrival",
          nooffiles: retrivalTable.length,
          client_name: cust_name,
          custId: localStorage.getItem('CUST_ID'),
          userId: localStorage.getItem('User_ID'),
          bookingId: BookingId,
          requestFieldType: fileBoxType
        });
      });

      if (fileBoxType === "File") {
        response_result = await rmsRequest("RecordRetrival", "", "", currentDate, "", '', '', '',
          "", "", "", remarks, localStorage.getItem("User_ID"), fileid, locationid, requestid, localStorage.getItem("CUST_ID"),
          TYPE, "", "", "", "", "", BookingId, "");
      }
      else {
        response_result = await rmsRequest("RecordRetrival", "", "", currentDate, "", "", '', '',
          "", "", "", remarks, localStorage.getItem("User_ID"), fileid, locationid, requestid, localStorage.getItem("CUST_ID"),
          TYPE, "", "", "", '', '', BookingId);
      }
      apiCalled(response_result, data, locationid)
    }
  };

  const apiCalled = async (response_result, data, locationid) => {

    if (response_result === 'Inserted') {
      await InsertSubRecordReterival(data)
      await UpdateIdCount(locationid, count);
      await Mail(data)
      sweetAlertModel('Success', 'Data Inserted', 'success', '/Dashboard')
    }
    else {
      sweetAlertModel('Error', `Server Error`, 'error', '/RecordRetrival')
    }
  }


  return (
    <>
      <div className="generatorlogcontainer">
        <Navbar />
        <div className="rec_retrival">
          <div className="svg_div">
            <img src={svg} alt="Dox And Box Bg" />
          </div>
          <form className="bg-light" style={{ margin: "0px 20px 0px 15px", boxShadow: "5px 5px 4px grey", padding: "20px 15px 0px", minHeight: "65vh", borderRadius: "7px" }} >
            <h3 className="mb-5">  Record Retrival <BsFillChatSquareQuoteFill style={{ margin: "0 0 -9px 0", fontSize: "30px" }} /></h3>
            <div className="d-flex row mb-4">
              <div className="col-md-5">
                <label htmlFor="type" >Type <span className="text-danger">*</span> </label>
                <input className="form-control " id="type" type="text" disabled defaultValue='File' />
                {/* <select className="form-control " id="type" onChange={handleChangeType} style={mandatoryfield.type ? errorFiledStyle : { height: "32px" }}>
                  <option value='' hidden>Select... </option>
                  <option>File</option>
                  <option>Box</option>
                </select> */}
                {mandatoryfield.type && (<p className="text-danger mb-0 mt-1">Please select Type</p>)}

              </div>

              <div className="col-md-5">
                <label htmlFor="department" >Department <span className="text-danger">*</span></label>
                {
                  localStorage.getItem("Dptname") === "ADMIN" ?
                    <select className="form-control" id="department" style={mandatoryfield.deparment ? errorFiledStyle : { height: "32px" }}>
                      <option value='' hidden>Select Department</option>
                      {
                        department.map((element, index) => (
                          <option key={index} value={element.DEPTNAME}>{element.DEPTNAME}</option>
                        ))
                      }
                    </select>
                    :
                    <input type="text" id="department" className="form-control" style={{ height: '32px' }} defaultValue={localStorage.getItem("Dptname")} disabled />
                }

                {mandatoryfield.deparment && (<p className="text-danger mb-0 mt-1">Please select Department</p>)}
              </div>

            </div>
            <div className="row">
              <div className="col-md-5">
                <label htmlFor="remarks">Remarks</label>
                <textarea className="form-control" rows={3} id="remarks" style={{ resize: 'none' }} placeholder="Comments..."></textarea>
              </div>
            </div>
            <table className="table table-bordered  col-md-5 mt-2">
              <thead>
                <tr>
                  <th scope="col">File/Box no. <span className="text-danger">*</span></th>
                  <th scope="col">Type Of Retrival</th>
                  <th scope="col">Type Of Delivery</th>
                </tr>
              </thead>
              <tbody>
                {retrivalTable.map((element, index) => (
                  <tr key={index}>
                    <td className="col-md-4">
                      <Select options={fileBoxData} className="col form-select" isMulti={false} name="fileBox"
                        onChange={(e) => { handleChangeTableValue(e, index, fileBoxType) }} />
                    </td>

                    <td className="col-md-3">
                      <select id="typeOfRetrival" className="form-control" name="typesOfRetrival" style={{ height: "33px" }}
                        onChange={(e) => { handleSaveToArr(index, e) }}
                      >
                        <option value='' hidden>Choose ...</option>
                        <option>Digital (Scan)</option>
                        <option>Physical-Returnable</option>
                        <option>Physical-Permanent Out</option>
                        <option>Photocopy</option>
                        <option>Audit on Site</option>
                      </select>
                    </td>
                    <td className="col-md-3">
                      <select id="deliverytype" className="form-control" name="typesOfDelivery" style={{ height: "33px" }}
                        onChange={(e) => { handleSaveToArr(index, e) }}
                      >
                        <option value='' hidden> Choose ...</option>
                        <option>Standard</option>
                        <option>Urgent</option>
                        {/* <option>Express Delivery</option> */}
                      </select>
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
            {mandatoryfield.fileBoxIndexStatus && (<p className="text-danger mt-0">Please select File/Box No at row {mandatoryfield.fileBoxIndex}</p>)}

            <button className="btn dark_btn" onClick={handleRemove}> Remove </button>
            <button className="btn btn-danger mx-2" onClick={handleAdd}> Add Row</button>
            <hr />
            <div className="form-group ">
              <button type="submit" className="maroon_btn btn float-right"
                onClick={handleClick}
              >Submit</button>
              <button type="reset" className="dark_btn btn mr-4 float-right" onClick={() => { window.location.reload() }}> Reset</button>
            </div>
          </form>
        </div >
      </div >
      <Footer />
    </>
  );
}

export default RecordRetrival;
