import React, { useEffect, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { AuthTokenVerify } from '../../api'
import LoadingPage from '../LoadingPage/LoadingPage';


const PrivatRoute = ({ children }) => {
    const [authValidate, setAuthValidate] = useState(false)
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        const fetchData = async () => {
            let token = localStorage.getItem('dox_BoxToken')
            if (token) {
                const isValid = await AuthTokenVerify(token)
                if (isValid === 'Token Verify') {
                    setAuthValidate(true)
                }
                else {
                    alert('Session Timeout')
                    localStorage.clear()
                    setAuthValidate(false)
                }
                setLoading(true)
            } else {
                setLoading(true)
                localStorage.clear()
                setAuthValidate(false)
            }
        }
        fetchData()
    }, [children])

    // const auth = localStorage.getItem('User_ID');
    return <>
        {
            loading ?
                authValidate ? <>{children}</> : <Navigate to={{ pathname: '/' }} />
                : <LoadingPage/>
        }
    </>
}

export default PrivatRoute;