import "./LoadingPage.css"
const LoadingPage = () => {
    return (
        <div className="loading-page-wrapper">
            <nav className="loading-nav d-flex justify-content-between align-items-center">
                <div className="loading-nav-menu d-flex justify-content-around align-items-center">
                    <div className="loading-nav-bar"></div>
                    <div className="loading-nav-logo"></div>
                </div>
                <div className="loading-nav-logout mr-4"></div>
            </nav>
            <section className="loading-section d-flex flex-column">
                <div className="loading-card-title mx-auto px-4"><div className="loading-card-inner-title"></div></div>
                <div className="loading-card-container p-3 d-flex justify-content-between mx-auto">
                    <div className="loading-card"></div>
                    <div className="loading-card"></div>
                    <div className="loading-card"></div>
                </div>
                <div className="loading-card-title mx-auto px-4"><div className="loading-card-inner-title"></div></div>
                <div className="loading-card-container p-3 d-flex justify-content-between mx-auto">
                    <div className="loading-card"></div>
                    <div className="loading-card"></div>
                    <div className="loading-card"></div>
                </div>
            </section>
            <footer className="loading-footer d-flex justify-content-center align-items-center">
                <div className="loading-footer-text"></div>
            </footer>
        </div>
    )
}

export default LoadingPage