import React, { useEffect, useState } from "react";
import SideBar from "./Sidebar";
import "./Navbar2.css";
import { Totallocation } from "../../api/index";
import { HiMenu } from "react-icons/hi";
import { ImLocation } from "react-icons/im";
import logo from "../Images/logoWithoutText.png";
import { CgLogOut } from 'react-icons/cg';
import { useNavigate } from "react-router-dom";

function Navbar() {
  const [on, setOn] = React.useState(true);
  const [totallocation, setTotallocation] = useState([]);

  const navigate = useNavigate();

  useEffect(() => {
    const fetch = async () => {
      const result = await Totallocation();
      setTotallocation(result);
    };

    localStorage.getItem('Dptname') === 'ADMIN' && fetch()
  }, []);

  const handleClick = () => {
    localStorage.clear();
    navigate('/')
  };

  const handleChange = (e) => {
    const string = e.target.value;
    const split = string.split(",");

    localStorage.setItem("Warehouse_ID", split[0]);
    localStorage.setItem("Wh_name", split[1]);
    window.location.reload();
  };

  const handleOn = () => {
    setOn(!on);
  };


  return (
    <>
      <nav className="Navbar">
        <div className="brand_slidericon d-flex">
          <HiMenu className="text-white cursor-pointer" onClick={handleOn} style={{ fontSize: "37px" }} />
          <div className="d-flex justify-content-center w-75 mt-1">
            <img style={{ width: "50px", height: "50px", margin: "-7px 0 0 0" }} src={logo} alt="Dox And Box Logo" />
            <p className="text-white">DOX & BOX</p>
          </div>
        </div>
        <div className="nav_content ">
          <div className="mx-1" style={{ borderRight: "1px solid", padding: "0 20px" }} >
            &nbsp;
            {localStorage.getItem("Dptname") !== "null" ? localStorage.getItem("Dptname") : ""}
          </div>
          <div className="mx-2" style={{ padding: "0 20px" }} >
            {localStorage.getItem("User_Name")}
          </div>
          <div className="nav-location" style={{ padding: "0 20px" }}>
            <ImLocation style={{ fontSize: "20px", margin: "0 0 -3px 0" }} />
            <select className="bg-transparent border-0" onChange={handleChange} >
              <option hidden>{localStorage.getItem("Wh_name")} </option>
              {
                totallocation.map((ele) => (
                  <option key={ele.WHid} value={`${ele.WHid},${ele.WHname}`} style={{ fontSize: "17px" }}> {ele.WHname.trim()} </option>
                ))
              }
            </select>
          </div>

          <div className="nav-logout cursor-pointer" onClick={handleClick} style={{ padding: "0 20px" }}>
            <CgLogOut style={{ fontSize: "28px", margin: "0 0 -3px 0" }} />Logout
          </div>
        </div>
      </nav>
      <SideBar openClass={on} />
    </>
  );
}

export default Navbar;