import React, { useEffect, useState } from "react";
import Navbar from "../Navbar/Navbar";
import { ProfileDetails } from "../../api";
import './profile.css'
import { HiUser } from 'react-icons/hi';
import Footer from '../Navbar/Footer'

const Profile = () => {
    const [profile, setProfile] = useState({});


    useEffect(() => {
        const profile = async () => {
            const data = await ProfileDetails(localStorage.getItem("CUST_ID"));
            setProfile(data);
        }
        profile();
    }, []);


    return (
        <>
            <div className="TotalProfilediv">
                <Navbar />
                <div className="profile_div">
                    <div className="profile_div_inside">
                        <div className="prof_icon_div">
                            <HiUser className="prof_icon" style={{}} />
                            <h4>{profile.custname}</h4>
                            <h5>Member Since :- {profile.custdoe ? profile.custdoe : 'NA'}</h5>
                        </div>
                        <div className="prof_content">
                            <h4>Contact Information</h4>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Email </label>
                                    <p>{profile.custemail}</p>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Mobile </label>
                                    <p>{profile.custcontactno ? profile.custcontactno : 'NA'}</p>
                                </div>
                            </div>
                            <div className="form-group">
                                <label>Address 1 </label>
                                <p>{profile.custadd}</p>
                            </div>
                            <div className="form-group">
                                <label>Address 2</label>
                                <p>{profile.custadd1 ? profile.custadd1 : 'NA'}</p>
                            </div>

                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>Country</label>
                                    <p>{profile.custcountry}</p>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>State</label>
                                    <p>{profile.custstate}</p>

                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group col-md-6">
                                    <label>City</label>
                                    <p>{profile.custcity}</p>
                                </div>
                                <div className="form-group col-md-6">
                                    <label>Pincode </label>
                                    <p>{profile.custpin}</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

            </div>
            <Footer />
        </>
    )
}

export default Profile;